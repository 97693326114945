import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Typography from "../Typography";
import { Container } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";

function SearchContainer() {
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  // Resetear el estado al cambiar de ruta
  useEffect(() => {
    setSearchTerm("");
    setSearchOpen(false);
  }, [location.pathname]);

  const handleSearchClick = () => {
    setSearchOpen(!searchOpen);
    navigate(`/busqueda?term=${searchTerm}`);
  };

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearchClick();
    }
  };

  return (
    <Container className="d-flex d-md-none d-lg-none" style={{ marginBottom: "2%", width: "auto", marginTop: "2%" }}>
      <FloatingLabel
        controlId="floatingInputGrid"
        label={<Typography text={"Buscar productos"} style={{ fontWeight: "normal", color: "silver" }}></Typography>}
        className="d-flex align-items-center"
        style={{ width: "100%" }}
      >
        <Form.Control
          type="email"
          className="search-input"
          placeholder=""
          style={{
            border: "none",
            width: "100%",
          }}
          value={searchTerm}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
        />
        <InputGroup.Text
          style={{
            border: "none",
            background: "none",
            cursor: "pointer",
          }}
          onClick={handleSearchClick}
        >
          <FontAwesomeIcon
            icon={faSearch}
            style={{
              color: searchOpen || searchTerm ? "black" : "#000", // Cambia el color a azul si hay un término de búsqueda
            }}
          />
        </InputGroup.Text>
      </FloatingLabel>
    </Container>
  );
}

export default SearchContainer;
