import React, { useRef } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

const ModalEditImage = ({ imageUrl, showModalImage, closedModalImage, handleImage, handleImageChange }) => {
  const cropperRef = useRef(null);

  const handleCrop = () => {

    if (!cropperRef.current) {
      return;
    }
    const canvas = cropperRef.current.cropper.getCroppedCanvas({
      width: 1200,
      height: 1200,
    });

    if (!canvas) {
      return;
    }
    canvas.toBlob((blob) => {
      if (!blob) {
        return;
      }
        const file = new File([blob], 'imagenFile.jpeg', {
        type: 'image/jpeg', // Define el tipo MIME del archivo, por ejemplo, 'image/png', 'image/jpeg', etc.
        lastModified: Date.now(), // Establece la fecha de modificación actual
      });
      handleImage(file);
      closedModalImage();
    }, 'image/jpeg')

  };

  return (
    <Modal show={showModalImage} onHide={closedModalImage} size="lg" backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Enviar imagen</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        
          <Cropper
             src={imageUrl}
             style={{width:"100%", height: '100%'}}
             aspectRatio={1 / 1}
             guides={false}
             viewMode={1}
             zoomable={true}
             cropBoxResizable={true} // Permitir que la caja de recorte sea redimensionable
             movable={true} // Permitir mover el área de recorte
             autoCrop={true} // Activar el recorte automático
             autoCropArea={1} // Establecer el tamaño inicial del área de recorte (0-1)
             minCropBoxWidth={1200} // Establece el ancho mínimo del área de recorte
             minCropBoxHeight={1200}
             ref={cropperRef}
          />
      
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={closedModalImage}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={handleCrop}>
          Aceptar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalEditImage;