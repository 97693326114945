import React, { useEffect, useState } from "react";
import { Button, Form, Alert } from "react-bootstrap";
import UnauthorizedPage from "../Exceptions/Unauthorized";
import { patchEditProfile } from "../../clients/phpApp";
import { baseUrlImgs } from "../../parametria";
import Loading from "../funcionalities/Loading";
import noImage from "../../assets/no-image.jpeg";
import ModalEditImage from "../../components/Modal/ModalEditImage";
import ModalSuccess from "../../components/Modal/ModalSuccess";


const UserProfile = (props) => {
  const { user, setUser, error, isLoading } = props;
  const [isEditing, setIsEditing] = useState(false);
  const [editedUser, setEditedUser] = useState({});
  const [newLogo, setNewLogo] = useState(null);
  const [updateError, setUpdateError] = useState(null);
  const [showModalImage, setShowModalImage] = useState(false);
  const [showModalSuccess, setShowModalSuccess] = useState(false);

  useEffect(() => {
    setEditedUser(user);
  }, []);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = async () => {
    try {
      const formData = new FormData();
      formData.append("description", editedUser.description);

      // Verificar si newLogo no es null antes de agregarlo al formData
      if (newLogo !== null) {
        formData.append("logo", newLogo);
      }

      await patchEditProfile(formData);

      setUser(editedUser);
      setIsEditing(false);
      setUpdateError(null);

      if (newLogo !== null) {
        window.location.reload();
      }

    } catch (error) {
      if (error.response.status === 422) {
        setUpdateError("No se ha hecho ningún cambio");
      } else {
        setUpdateError(
          "No se pudo actualizar el perfil. Por favor, inténtalo de nuevo."
        );
      }
    }
  };

  const handleCancel = () => {
    // Restablecer editedUser a los datos actuales del usuario
    setEditedUser(user);
    setNewLogo(null);
    setIsEditing(false);
    setUpdateError(null); // Limpiar el error al cancelar la edición
  };

  const handleChange = (e) => {
    // Actualizar el estado editedUser basado en la entrada del usuario
    setEditedUser({
      ...editedUser,
      [e.target.name]: e.target.value,
    });
  };

  const handleLogoChange = (e) => {
    // Actualizar el estado newLogo con el nuevo archivo de imagen seleccionado
    setNewLogo(e.target.files[0]);
    setShowModalImage(true);
  };

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : error ? (
        <UnauthorizedPage />
      ) : (
        <div className="user-profile">
          <div>
            <div className="flex-column">
              <div className="d-flex flex-row">
                <img
                  src={
                    isEditing && newLogo
                      ? URL.createObjectURL(newLogo)
                      : user.logo !== "none"
                      ? baseUrlImgs + user.logo
                      : noImage
                  }
                  alt="User Avatar"
                  width="150"
                  height="150"
                />
                {updateError && (
                  <Alert variant="danger" className="mt-5 w-100 h-50">
                    {updateError}
                  </Alert>
                )}
              </div>
              {isEditing && (
                <Form.Group controlId="formLogo" className="mb-3 mt-3">
                  <Form.Label>Nuevo logo</Form.Label>
                  <Form.Control
                    type="file"
                    accept="image/*"
                    onChange={handleLogoChange}
                  />
                </Form.Group>
              )}
            </div>
          </div>
          <div className="user-info">
            {isEditing ? (
              <Form>
                <Form.Group className="mb-3" controlId="formName">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={editedUser.name}
                    onChange={handleChange}
                    disabled
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formDescription">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="description"
                    value={editedUser.description}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="text"
                    name="email"
                    value={editedUser.email}
                    onChange={handleChange}
                    disabled
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formWhatsApp">
                  <Form.Label>WhatsApp</Form.Label>
                  <Form.Control
                    type="text"
                    name="whatsapp"
                    value={editedUser.whatsapp}
                    onChange={handleChange}
                    disabled
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formInstagram">
                  <Form.Label>Instagram</Form.Label>
                  <Form.Control
                    type="text"
                    name="instagram"
                    value={editedUser.instagram}
                    onChange={handleChange}
                    disabled
                  />
                </Form.Group>

                <Button variant="primary" onClick={handleSave}>
                  Save
                </Button>
                <Button variant="secondary" onClick={handleCancel}>
                  Cancel
                </Button>
              </Form>
            ) : (
              <>
                <h2>{user.name}</h2>
                <p>{user.description}</p>
                <p>
                  <strong>Email:</strong> {user.email}
                </p>
                <p>
                  <strong>WhatsApp:</strong> {user.whatsapp}
                </p>
                <p>
                  <strong>Instagram:</strong> {user.instagram}
                </p>
                <Button variant="primary" onClick={handleEdit}>
                  Editar
                </Button>
              </>
            )}
          </div>
        </div>
      )}
      {/* Modales */}
      <ModalEditImage
        imageUrl={isEditing && newLogo ? URL.createObjectURL(newLogo) : ""}
        showModalImage={showModalImage}
        closedModalImage={() => setShowModalImage(false)}
        handleImage={(file) => {
          setNewLogo(file);
          setShowModalImage(false);
        }}
      />
      <ModalSuccess
        showModalSuccess={showModalSuccess}
        closedModalSuccess={() => setShowModalSuccess(false)}
      />
    </>
  );
};

export default UserProfile;
