import React, { useState, useEffect, useRef } from 'react';
import { useSwipeable } from 'react-swipeable';
import './Article.css'; // Importa el archivo CSS

const ArticleSection = () => {
    const [currentArticle, setCurrentArticle] = useState(1);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1100);
    const articles = [
        { title: 'Showroom digital', text: 'Un espacio para emprendedores, <br>creado por emprendedores.', image: 'https://i.postimg.cc/8PLkyRKg/Group-3.png' },
        { title: 'Emprendedores', text: 'Presentá tu marca en nuestro showroom y <br>aprovecha del efecto red.', image: 'https://i.postimg.cc/NjYsZKS9/Vector.png' },
        { title: '¿Buscás productos?', text: 'Calidad y originalidad en cada producto. <br>¡Explorá ahora!', image: 'https://i.postimg.cc/6QbB7Y3n/Group-4.png' }
    ];

    const totalArticles = articles.length;
    const carouselRef = useRef(null);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 1100);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleTransitionEnd = () => {
        if (!isMobile) return;

        if (currentArticle === 0) {
            setCurrentArticle(totalArticles);
            const articlesElement = carouselRef.current;
            articlesElement.style.transition = 'none';
            articlesElement.style.transform = `translateX(-${totalArticles * 100}%)`;
            setTimeout(() => {
                articlesElement.style.transition = 'transform 0.5s ease-in-out';
            }, 0);
        } else if (currentArticle === totalArticles + 1) {
            setCurrentArticle(1);
            const articlesElement = carouselRef.current;
            articlesElement.style.transition = 'none';
            articlesElement.style.transform = `translateX(-100%)`;
            setTimeout(() => {
                articlesElement.style.transition = 'transform 0.5s ease-in-out';
            }, 0);
        }
    };

    const handleDotClick = (index) => {
        setCurrentArticle(index + 1);
    };

    const handlers = useSwipeable({
        onSwipedLeft: () => setCurrentArticle((prevArticle) => prevArticle + 1),
        onSwipedRight: () => setCurrentArticle((prevArticle) => prevArticle - 1),
        onSwiping: (eventData) => {
            if (!isMobile) return;

            const percentage = eventData.deltaX / window.innerWidth * 100;
            const newTransform = `translateX(calc(-${currentArticle * 100}% + ${percentage}%))`;
            const articlesElement = carouselRef.current;
            articlesElement.style.transition = 'none';
            articlesElement.style.transform = newTransform;
        },
        onSwiped: () => {
            if (!isMobile) return;

            const articlesElement = carouselRef.current;
            articlesElement.style.transition = 'transform 0.5s ease-in-out';
            articlesElement.style.transform = `translateX(-${currentArticle * 100}%)`;
        },
        preventDefaultTouchmoveEvent: true,
        trackMouse: true,
    });

    return (
        <div className="carousel-container" {...handlers}>
            <div
                className={`articles ${isMobile ? 'mobile' : 'desktop'}`}
                ref={carouselRef}
                onTransitionEnd={handleTransitionEnd}
                style={isMobile ? { transform: `translateX(-${currentArticle * 100}%)` } : {}}
            >
                {isMobile
                    ? [articles[totalArticles - 1], ...articles, articles[0]].map((article, index) => (
                        <div key={index} className="article">
                            <h3>{article.title}</h3>
                            <div className='p-container' dangerouslySetInnerHTML={{ __html: article.text }} />
                        </div>
                    ))
                    : articles.map((article, index) => (
                        <div key={index} className="article">
                            <h3>{article.title}</h3>
                            <div className='p-container' dangerouslySetInnerHTML={{ __html: article.text }} />
                        </div>
                    ))
                }
            </div>
            {isMobile && (
                <div className="dots">
                    {articles.map((_, index) => (
                        <span
                            key={index}
                            className={`dot ${index + 1 === currentArticle ? 'active' : ''}`}
                            onClick={() => handleDotClick(index)}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

export default ArticleSection;
