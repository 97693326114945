// ResetPassword.jsx

import React, { useState } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { Container } from 'react-bootstrap';
import ModalSuccess from '../../components/Modal/ModalSuccess';
import { useParams } from 'react-router-dom'; // Importa useParams para obtener el token de la URL
import { patchResetPassword } from '../../clients/phpApp'; // Importa la función patchResetPassword
import './styles/ResetPassword.css';

const ResetPassword = ({ handleShowNavHead }) => {
  const { api_token } = useParams(); // Obtén el token de la URL
  const [showModalSuccess, setShowModalSuccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const schema = yup.object().shape({
    email: yup.string()
      .email('Ingresa un email válido.')
      .required('El email es requerido.'),
    password: yup.string()
      .required('La contraseña es requerida.')
      .min(8, 'La contraseña debe tener al menos 8 caracteres.')
      .max(16, 'La contraseña no puede tener más de 16 caracteres.')
      .matches(/^(?=.*[A-Z])/, 'La contraseña debe contener al menos una letra mayúscula.')
      .matches(/^(?=.*[a-z])/, 'La contraseña debe contener al menos una letra minúscula.')
      .matches(/^(?=.*\d)/, 'La contraseña debe contener al menos un número.')
      .matches(/^(?=.*[\W_])/, 'La contraseña debe contener al menos un carácter especial.'),
    confirmPassword: yup.string()
      .required('Debes confirmar la contraseña.')
      .oneOf([yup.ref('password'), null], 'Las contraseñas deben coincidir.')
  });

  const handleSubmit = async (values) => {
    setIsSubmitting(true);
    try {
      console.log("Datos enviados al backend:", {
        api_token,
        email: values.email,
        password: values.password
      });
      await patchResetPassword({
        api_token,
        email: values.email,
        password: values.password
      });
      setIsSubmitting(false);
      setShowModalSuccess(true);
    } catch (error) {
      setIsSubmitting(false);
      // Manejo de errores aquí
    }
  };

  return (
    <Container className="custom-container">
      <div className="contenedor-reset-password">
        <Formik
          validationSchema={schema}
          initialValues={{
            email: '',
            password: '',
            confirmPassword: ''
          }}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          {({ handleSubmit, handleChange, values, touched, errors }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Form.Group className="mb-3">
                <FloatingLabel controlId="email" label="Email">
                  <Form.Control
                    type="email"
                    placeholder="Email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    isInvalid={touched.email && !!errors.email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>
              <Form.Group className="mb-3">
                <FloatingLabel controlId="password" label="Nueva Contraseña">
                  <Form.Control
                    type="password"
                    placeholder="Nueva Contraseña"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    isInvalid={touched.password && !!errors.password}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.password}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>
              <Form.Group className="mb-3">
                <FloatingLabel controlId="confirmPassword" label="Confirmar Contraseña">
                  <Form.Control
                    type="password"
                    placeholder="Confirmar Contraseña"
                    name="confirmPassword"
                    value={values.confirmPassword}
                    onChange={handleChange}
                    isInvalid={touched.confirmPassword && !!errors.confirmPassword}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.confirmPassword}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>
              <Button
                variant="primary"
                type="submit"
                disabled={isSubmitting}
                className="custom-button"
              >
                {isSubmitting ? (
                  <>
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                    <span className="visually-hidden">Enviando...</span>
                  </>
                ) : (
                  'Guardar'
                )}
              </Button>
            </Form>
          )}
        </Formik>
      </div>
      {showModalSuccess && <ModalSuccess
        showModalSuccess={showModalSuccess}
        closedModalSuccess={() => setShowModalSuccess(false)}
        message="¡Contraseña actualizada correctamente!"
      />}
    </Container>
  );
};

export default ResetPassword;
