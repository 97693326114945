import React from "react";
import './Footer.css'

function openWhatsApp() {
    const message = `Hola deNicho, me interesa saber más  sobre ustedes...`;
    const whatsappLink = `https://wa.me/59898518884?text=${encodeURIComponent(message)}`;
    window.open(whatsappLink, "_blank");
}

const Footer = () => {
    return (
        <div className="footer">
            <div className="footer-content">
                <h4>deNicho</h4>

                <div className="social-media">
                    <a onClick={openWhatsApp}>
                        <i className="fab fa-whatsapp"></i>
                    </a>
                    <a href="https://www.instagram.com/denichomarket/">
                        <i className="fab fa-instagram"></i>
                    </a>
                    <a href="mailto:admin@denicho.com">
                        <i className="fas fa-envelope"></i> {/* Email icon */}
                    </a>
                </div>

                <hr />

                <div className="contacts">
                    <div>
                        <a href="https://www.instagram.com/denichomarket/">
                            <i className="fab fa-instagram"></i>
                            <p>denichomarket</p>
                        </a>
                    </div>
                    <div>
                        <a href="mailto:admin@denicho.com">
                            <i className="fas fa-envelope"></i> {/* Email icon */}
                            <p>admin@denicho.com</p>
                        </a>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Footer