import React, { useState } from "react";
import { useEffect } from "react";
import { getEntrepreneursProtected } from "../../clients/phpApp";
import { CDBDataTable, CDBCard, CDBCardBody } from "cdbreact";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAlignJustify, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { patchActivateEntrepreneur } from "../../clients/phpApp";
import Unauthorized from '../Exceptions/Unauthorized'
import Loading from '../funcionalities/Loading'

const DataTableEntrepreneurs = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [entrepreneurs, setEntrepreneurs] = useState([]);
  const [error, setError] = useState(false);

  const handleDisable = async (entrepreneurId, entrepreneurStatus) => {
    setIsLoading(true)
    try {
      const updatedStatus = await patchActivateEntrepreneur(
        entrepreneurId,
        !entrepreneurStatus
      );  
      
      const updateRows = ((prevRows) => {
        return prevRows.map((entrepreneur) => {
          return entrepreneur.id === entrepreneurId
            ? { ...entrepreneur, active: updatedStatus.data.active}
            : entrepreneur;
        });
      });
      setEntrepreneurs(updateRows);
      setIsLoading(false)
      return updatedStatus

    } catch (error) {
      // Manejar el error y establecer el mensaje de error
      setIsLoading(false)
    }
  };

    useEffect(() => {
        setIsLoading(true);
        getEntrepreneursProtected()
          .then((data) => {
            setEntrepreneurs(data);
        }).catch((error) => {
            console.error(error);
            setError(true)
          })
          .finally(() => {
            setIsLoading(false);
          });
      }, []);

      const dataTableData = () => {
        if (!entrepreneurs) {
          return {
            columns: [],
            rows: [],
          };
        }
      const rows = entrepreneurs.map((entrepreneur) => {
        return {
          active: entrepreneur.active,
          id: entrepreneur.id,
          name: entrepreneur.name,
          category: entrepreneur.category.name,
          email: entrepreneur.email,
          whatsapp: entrepreneur.whatsapp,
          disable: (
          <span
            style={{ faAlignJustify, cursor: "pointer" }}
            className={`text-${entrepreneur.active === 1 ? "success" : "danger"}`}
            onClick={() => handleDisable(entrepreneur.id, entrepreneur.active)}
          >
            <FontAwesomeIcon
              icon={(entrepreneur.active === 1 ? faEye : faEyeSlash)}
              style={{ marginRight: "5px" }}
            />
          </span>
        )    
    }
 })

  return {
    columns: [
      {
        label: "Name",
        field: "name",
        width: 30,
      },
      {
        label: "Categoria",
        field: "category",
        width: 30,
      },
      {
        label: "Correo Electronico",
        field: "email",
        width: 30,
      },
      {
        label: "Whatsapp",
        field: "whatsapp",
        width: 30,
      },
      {
        label: "Desactivar",
        field: "disable",
        width: 30,
      },
    ],
    rows: rows
  };
}

  return ( 
    <div style={{paddingLeft:"40px", paddingRight: "40px"}}>
      {error ? (<Unauthorized/>) : ( 
      <CDBCard>
        <CDBCardBody>
          <div className="tableContainer" style={{padding:"20px"}}>  
          <CDBDataTable
            striped
            bordered
            hover
            responsive
            entriesOptions={[10, 20, 30, 100]}
            entries={10}
            pagesAmount={4}
            data={dataTableData()}
            materialSearch={true}
            infoLabel={["Mostrando", "a", "de", "productos"]}
            entriesLabel="Mostrar"
            searchLabel={"Buscar"}
            noBottomColumns
            noRecordsFoundLabel="No se encontraron registros"
          />
        </div>
      </CDBCardBody>
    </CDBCard>
      )}
    </div>
  );
};

export default DataTableEntrepreneurs;