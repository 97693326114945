import React, { useEffect, useState } from "react";
import CardProduct from "../Cards/CardProduct";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./styles/ProductsEntre.css";
import { Container } from "react-bootstrap";
import Typography from "../Typography";
import ModalProduct from "../Modal/ModalProduct";
import { baseUrlImgs } from "../../parametria";
import noImage from "../../assets/no-image.jpeg";

function ProductsEntre(props) {
  const {
    entrepreneurs,
    sendIdCategory,
    idCategory,
    showButtonCategory,
    showButtonVisitProfile,
    visitProfileEntrepreneur,
    applySlice,
    searching,
  } = props;
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({});
  const [category, setCategory] = useState(idCategory);
  const categories = { 1: "Arte", 2: "Home&Deco", 3: "Moda" };
  const [buttonCategories, setButtonCategories] = useState("");

  useEffect(() => {
    const updatedButtonCategories = { ...categories };
    delete updatedButtonCategories[idCategory];
    setCategory(idCategory); // Actualiza la categoría en el estado
    setButtonCategories(updatedButtonCategories);
  }, [idCategory]);

  const handleOpenModal = (content) => {
    setModalContent(content);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  function changeCategory(newCategoryId) {
    setCategory(newCategoryId);
    sendIdCategory(newCategoryId);
  }

  return (
    <Container className="productsStyleEntre">
      {showButtonCategory && (
        <Row>
          <div className="d-flex align-items-center justify-content-between category-title">
            <Col>
              <Typography as={"h2"} text={categories[category]} />
            </Col>
          </div>
        </Row>
      )}
      {entrepreneurs.map((entrepreneur) => (
        <Row key={entrepreneur.name}>
          {entrepreneur.products
            .filter(product => product.active) // Filtrar productos activos
            .map((product) => (
              <Col key={product.id} xs={6} md={4} lg={3}>
                <CardProduct
                  entrepreneur={entrepreneur.name}
                  id={entrepreneur.id}
                  image={[
                    product.image1 !== "none"
                      ? baseUrlImgs + product.image1
                      : noImage,
                    product.image2 !== "none"
                      ? baseUrlImgs + product.image2
                      : null,
                    product.image3 !== "none"
                      ? baseUrlImgs + product.image3
                      : null,
                  ].filter(Boolean)}
                  title={product.name}
                  description={product.description}
                  price={Math.floor(product.price)}
                  onProductClick={handleOpenModal}
                />
              </Col>
            ))}
        </Row>
      ))}

      {isModalOpen && (
        <ModalProduct
          onClose={handleCloseModal}
          content={modalContent}
          show={isModalOpen}
          showButtonVisitProfile={showButtonVisitProfile}
          visitProfileEntrepreneur={visitProfileEntrepreneur}
        />
      )}
    </Container>
  );
}

export default ProductsEntre;
