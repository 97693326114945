import Typography from "../Typography";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faWhatsapp, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./styles/EntrepreneurInfo.css"
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Badge from 'react-bootstrap/Badge';
import { baseUrlImgs } from "../../parametria";
import noImage from "../../assets/no-image.jpeg"

function EntrepreneurInfo(props) {
  const { entrepreneurs, category, handleModal } = props;

  function handleClick() {
    handleModal(true);
  }

  function openWhatsApp() {
    const message = `Hola ${entrepreneurs[0].name}, quiero saber más sobre tus productos...`;
    const whatsappLink = `https://wa.me/598${entrepreneurs[0].whatsapp}?text=${encodeURIComponent(message)}`;
    window.open(whatsappLink, "_blank");
  }

  return (
    <div className="sidebar-entrepreneurInfo text-container">

      <Row>
        <div className="d-flex align-items-center justify-content-center">
          <img
            src={entrepreneurs[0].logo !== "none" ? baseUrlImgs + entrepreneurs[0].logo : noImage}
            alt="Imagen del emprendedor"
            style={{
              width: "200px", // ancho
              height: "200px", // altura
              borderRadius: "50%", // para redondear imagen
            }}
          />
        </div>
      </Row>

      <Row>
        <div className="entrepreneurInfo">
          <div style={{ marginBottom: "30px" }}>
            <Typography
              text={entrepreneurs[0].name}
              style={{
                fontSize: "25px",
                color: "rgb(0, 0, 0)",
                padding: "10px",
                margin: "10px"
              }}
            />
          </div>
        </div>
      </Row>
      <Row className="justify-content-center">
        <Col xs="auto" className="p-3 p-md-2 p-lg-3" onClick={handleClick} id="email-icon">
          <FontAwesomeIcon icon={faEnvelope} className="pointer icon-grande"/>
        </Col>
        <Col xs="auto" className="p-3 p-md-2 p-lg-3" onClick={openWhatsApp} id="whatsapp-icon">
          <FontAwesomeIcon icon={faWhatsapp} className="pointer icon-grande"/>
        </Col>
        <Col xs="auto" className="p-3 p-md-2 p-lg-3" id="instagram-link">
          <a href={`${entrepreneurs[0].instagram}`} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }} id="instagram-link">
            <FontAwesomeIcon icon={faInstagram} className="pointer icon-grande"/>
          </a>
        </Col>
      </Row>
      <Row xs="auto" className="justify-content-center" >
        <div className="description-style">
          <Typography
            text={entrepreneurs[0].description}
            style={{ textAlign: "justify" }}
          />
        </div>
      </Row>
    </div>
  );
}

export default EntrepreneurInfo;
