import React, { useState, useEffect } from 'react';
import './Banner.css';
import imagenBanner1 from '../../assets/imagen_banner/imagen_banner1.jpg';
import imagenBanner2 from '../../assets/imagen_banner/imagen_banner2.jpg';

const BannerSection = () => {
    const [currentImage, setCurrentImage] = useState(0);

    const images = [
        { url: imagenBanner1, title1: "Impulsá", titlespan1: "tu emprendimiento", title2: "en nuestro ", titlespan2: " showroom digital", button: "Registrarse" },
        { url: imagenBanner2, title1: "Explorá un mundo", titlespan1: "deNicho", button: "Emprendedores" }
        // Puedes agregar más imágenes y títulos según sea necesario
    ];

    const changeImage = (increment) => {
        setCurrentImage((prevImage) => (prevImage + increment + images.length) % images.length);
    };

    useEffect(() => {
        const intervalId = setInterval(() => changeImage(1), 5000);

        return () => clearInterval(intervalId);
    }, []); 

    return (
        <div className="banner-section">
            <div className='hero-text'>
                <h1>{images[currentImage].title1} <span>{images[currentImage].titlespan1} </span>{images[currentImage].title2}<span>{images[currentImage].titlespan2} </span></h1>
                <div className='CTA'>
                    <a href={images[currentImage].button === 'Registrarse' ? '/registro' : images[currentImage].button === 'Emprendedores' ? '/emprendedores' : '/default-path'}>{images[currentImage].button}</a>
                </div>
            </div>

            <img className="banner-image"
                src={images[currentImage].url}
                alt={`Banner ${currentImage + 1}`}
                style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    filter: 'brightness(50%)'
                }}
            />

        </div>
    );
};

export default BannerSection;
