import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate, useLocation } from 'react-router-dom';
import { getSearchProduct } from "../../clients/phpApp";
import Products from "../../components/Products/Products";
import ServiceUnavailable from "../../components/Exceptions/ServiceUnavailable";
import Loading from "../../components/funcionalities/Loading";
import "./styles/ProductSearch.css";

function ProductsSearch(props) {
  const { updateIdEmprendedor } = props;
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const searchTerm = new URLSearchParams(location.search).get('term');

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError(false); // Resetear el estado de error antes de la nueva búsqueda

      try {
        if (!searchTerm) {
          setProducts([]);
          setIsLoading(false);
          return;
        }

        const data = await getSearchProduct(searchTerm);
        if (data.data.length === 0) {
          setProducts([]);
        } else {
          setProducts(data.data);
        }
      } catch (error) {
        console.error("Error al buscar productos", error);
        setError(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [searchTerm]);

  function visitProfileEntrepreneur(id) {
    updateIdEmprendedor(id);
    navigate(`/emprendedor/${id}`);
  }

  return (
    <Container fluid>
      {isLoading ? (
        <Loading />
      ) : error ? (
        <ServiceUnavailable />
      ) : products.length === 0 ? (
        <div className="no-results-container">
          <p className="no-results-message">
            No se encontraron productos con el término de búsqueda "{searchTerm}".
          </p>
        </div>
      ) : (
        <Row>
          <Col>
            <Products
              entrepreneurs={false}
              products={products}
              showButtonCategory={false}
              showButtonVisitProfile={true}
              visitProfileEntrepreneur={visitProfileEntrepreneur}
              searching={true}
            />
          </Col>
        </Row>
      )}
    </Container>
  );
}

export default ProductsSearch;
