import React, { useEffect, useRef, useState } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import "./styles/NavBar.css";
import Typography from "../Typography";
import Search from "../funcionalities/Search";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import Login from "../Login/Login";
import { logout, logoutAdmin } from "../../clients/phpApp";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { getCategories } from "../../clients/phpApp";

function NavBar(props) {
  const {
    isShowLogin,
    handleLoginClick,
    isLoggedIn,
    setIsLoggedIn,
    setIsLoggedInAdmin,
    isLoggedInAdmin,
    setShowLogin,
    updateIdCategory,
  } = props;
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [categoriesDropdownOpen, setCategoriesDropdownOpen] = useState(false);
  const navRef = useRef(null);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const fetchedCategories = await getCategories();
        setCategories(fetchedCategories);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  const handleLogout = () => {
    if (isLoggedIn) {
      logout()
        .then((response) => {
          if (response.data === "Sesion cerrada") {
            localStorage.removeItem("api_token");
            setIsLoggedIn(false);
            navigate("/");
          } else {
            localStorage.removeItem("api_token");
            setIsLoggedIn(false);
            throw new Error("Error: Sesión no cerrada");
          }
        })
        .catch((error) => {
          localStorage.removeItem("api_token");
          setIsLoggedIn(false);
          throw new Error("Error: Sesión no cerrada");
        });
    } else {
      logoutAdmin()
        .then((response) => {
          if (response.data) {
            localStorage.removeItem("api_token_admin");
            setIsLoggedInAdmin(false);
            navigate("/");
          } else {
            localStorage.removeItem("api_token_admin");
            setIsLoggedInAdmin(false);
            throw new Error("Error: Sesión no cerrada");
          }
        })
        .catch((error) => {
          localStorage.removeItem("api_token_admin");
          setIsLoggedInAdmin(false);
          throw new Error("Error: Sesión no cerrada");
        });
    }
  };

  const handleNavCollapse = () => {
    setExpanded(!expanded);
    setCategoriesDropdownOpen(false); // Close categories dropdown when toggling navbar
  };

  const handleDropdownToggle = (isOpen) => {
    setCategoriesDropdownOpen(isOpen);
  };

  const handleClickOutside = (e) => {
    if (navRef.current && !navRef.current.contains(e.target)) {
      setExpanded(false);
      setCategoriesDropdownOpen(false); // Close categories dropdown on outside click
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const showEntrepreneurById = (id) => {
    updateIdCategory(id);
    navigate("/categorias");
  };

  const underStyle = {
    fontWeight: "bold",
    textDecoration: "none",
    color: "black",
    fontFamily: "'Jost', sans-serif",
    fontSize: "1.8rem",
    margin: "0.25rem 0", // Added margin top and bottom
  };

  const NavLinkWithMargin = ({ to, children }) => (
    <NavLink to={to} style={{ margin: "0.5rem 0" }}>
      {children}
    </NavLink>
  );

  return (
    <>
      <Navbar expand="md" className="menu" expanded={expanded} ref={navRef}>
        <div className="container-fluid d-flex d-md-none d-lg-none justify-content-between align-items-center">
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            onClick={handleNavCollapse}
            id="basic-navbar-nav"
          />
          <NavLink
            to="/"
            onClick={() => {
              setExpanded(false);
              setCategoriesDropdownOpen(false);
            }}
          >
            <img
              src="https://firebasestorage.googleapis.com/v0/b/canje-de7e9.appspot.com/o/Union%20(7).png?alt=media&token=430eb109-5fb9-4cf9-b098-a79e44a92116"
              alt=""
              style={{ height: "30px", width: "30px" }}
            />
          </NavLink>
          <NavLink
            to="/"
            style={underStyle}
            onClick={() => {
              setExpanded(false);
              setCategoriesDropdownOpen(false);
            }}
          >
            <span style={underStyle}>deNicho</span>
          </NavLink>
        </div>

        <Navbar.Collapse
          id="basic-navbar-nav"
          className="justify-space-between align-items-center"
        >
          <div className="divider d-flex d-md-none d-lg-none"></div>{" "}
          {/* Barra divisora horizontal */}
          <NavLink
            to="/"
            className="item-collapsed"
            style={{ textDecoration: "none", margin: "0.5rem 0" }}
            onClick={handleNavCollapse}
          >
            <Typography text="Home" />
          </NavLink>
          <div className="vr d-none d-md-flex d-lg-flex "></div>
          <NavDropdown
            title={<Typography text="Categorías" />}
            id="basic-nav-dropdown"
            className="item-collapsed"
            style={{ margin: "0.5rem 0" }}
            show={categoriesDropdownOpen}
            onToggle={handleDropdownToggle}
          >
            {categories.map((category) => (
              <NavDropdown.Item
                key={category.id}
                onClick={() => {
                  showEntrepreneurById(category.id);
                  handleNavCollapse();
                }}
              >
                <Typography text={category.name} />
              </NavDropdown.Item>
            ))}
          </NavDropdown>
          <div className="vr d-none d-md-flex d-lg-flex "></div>
          <Nav.Link href="/emprendedores" className="item-collapsed" style={{ margin: "0.5rem 0" }} onClick={handleNavCollapse}>
            <Typography text="Emprendedores" />
          </Nav.Link>

          <div className="guzman">
            {isLoggedIn || isLoggedInAdmin ? (
              <div>
                <NavLink to={isLoggedIn ? "/administracion" : "/admin"} style={{ margin: "0.5rem 0" }}>
                  <Typography text="Ir a mi perfil" />
                </NavLink>
                <div onClick={handleLogout} style={{ margin: "0.5rem 0" }}>
                  <Typography text="Cerrar Sesión" />
                </div>
              </div>
            ) : (
              <div>
                <NavLink to="/registro" style={{ margin: "0.5rem 0" }}>
                  <Typography text="Registrarse" />
                </NavLink>
                <div onClick={handleLoginClick} style={{ margin: "0.5rem 0" }}>
                  <Typography text="Iniciar Sesión" />
                </div>
              </div>
            )}
          </div>

          <div
            className="d-none d-md-flex d-lg-flex align-items-center"
            style={{ width: "20%" }}
          >
            <div className="vr" style={{ marginRight: "10%" }}></div>
            <Search style={{ marginTop: "10%" }}></Search>
          </div>
        </Navbar.Collapse>
      </Navbar>
      {isShowLogin && !isLoggedIn && (
        <Login
          handleLoginClick={handleLoginClick}
          setIsLoggedIn={setIsLoggedIn}
          setShowLogin={setShowLogin}
        />
      )}
    </>
  );
}

export default NavBar;
