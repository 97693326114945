// ForgotPassword.jsx

import React, { useState } from 'react';
import { Form, Button, FloatingLabel, Row, Spinner } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import { postForgotPassword } from '../../clients/phpApp';
import './styles/ForgotPassword.css';

const ForgotPassword = ({ setShowForgotPassword }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState('');

  const Schema = yup.object().shape({
    email: yup.string().email('Ingrese un correo electrónico válido').required('El correo electrónico es requerido.'),
  });

  const handlePost = async (email) => {
    setIsSubmitting(true);
    try {
      const response = await postForgotPassword({ email });
      setMessage('Se ha enviado un enlace de restablecimiento a su correo electrónico.');
      setIsSubmitting(false);
    } catch (error) {
      setMessage('Error al enviar el correo electrónico. Inténtelo de nuevo más tarde.');
      setIsSubmitting(false);
    }
  };

  return (
    <div className="forgot-password-style">
      <Formik
        validationSchema={Schema}
        initialValues={{ email: '' }}
        onSubmit={(values) => handlePost(values.email)}
      >
        {({ handleSubmit, handleChange, values, touched, errors }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <FloatingLabel controlId="email" label="Correo electrónico" className="mb-3">
              <Form.Control
                type="email"
                placeholder="Correo electrónico"
                name="email"
                value={values.email}
                onChange={handleChange}
                isInvalid={!!errors.email && touched.email}
              />
              <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
            </FloatingLabel>
            {message && (
              <Row className="mb-3 justify-content-center custom-message">{message}</Row>
            )}
            <Row className="mb-3">
              <Button
                variant="primary"
                type="submit"
                disabled={isSubmitting}
                style={{ width: '80%', marginLeft: '10%', marginTop: '10px' }}
                className={`custom-button ${isSubmitting ? 'custom-button-loading' : ''}`}
              >
                {isSubmitting ? (
                  <>
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="custom-spinner" />
                    <span className="visually-hidden">Enviando...</span>
                  </>
                ) : (
                  'Enviar link de restablecimiento'
                )}
              </Button>
            </Row>
            <Row className="mb-3 justify-content-center">
              <Button variant="link" onClick={() => setShowForgotPassword(false)}>
                Volver al inicio de sesión
              </Button>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ForgotPassword;
