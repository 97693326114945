import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CardProduct from "../Cards/CardProduct";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Container, Row, Col } from "react-bootstrap";
import Typography from "../Typography";
import ModalProduct from "../Modal/ModalProduct";
import { baseUrlImgs } from "../../parametria";
import noImage from "../../assets/no-image.jpeg";
import "./styles/Products.css";

function Products(props) {
  const {
    entrepreneurs,
    products,
    sendIdCategory,
    idCategory,
    showButtonCategory,
    showButtonVisitProfile,
    visitProfileEntrepreneur,
    searching,
  } = props;

  const [isModalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({});
  const [category, setCategory] = useState(idCategory);
  const categories = { 1: "Arte", 2: "Home&Deco", 3: "Moda" };
  const [buttonCategories, setButtonCategories] = useState("");

  useEffect(() => {
    const updatedButtonCategories = { ...categories };
    delete updatedButtonCategories[idCategory];
    setCategory(idCategory);
    setButtonCategories(updatedButtonCategories);
  }, [idCategory]);

  const handleOpenModal = (content) => {
    setModalContent(content);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
      partialVisibilityGutter: 0,
      arrows: true
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      partialVisibilityGutter: 0,
      arrows: true
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2.7,
      partialVisibilityGutter: 0,
      arrows: true
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1.8,
      partialVisibilityGutter: 0,
      arrows: false
    }
  };

  return (
    <Container className="productsStyle">
      {showButtonCategory && (
        <div className="d-flex align-items-center justify-content-between category-title">
          <Typography as={"h1"} text={categories[category]} />
        </div>
      )}
      {entrepreneurs === false ? (
        searching ? (
          <Row>
            {products.map((product) => (
              <Col key={product.id} sm={12} md={6} lg={4} xl={3} className="mb-4">
                <CardProduct
                  entrepreneur={product.entrepreneur.name}
                  id={product.entrepreneur.id}
                  image={[
                    product.image1 !== "none" ? baseUrlImgs + product.image1 : noImage,
                    product.image2 !== "none" ? baseUrlImgs + product.image2 : null,
                    product.image3 !== "none" ? baseUrlImgs + product.image3 : null,
                  ].filter(Boolean)}
                  title={product.name}
                  description={product.description}
                  price={Math.floor(product.price)}
                  onProductClick={handleOpenModal}
                />
              </Col>
            ))}
          </Row>
        ) : (
          products.map((productGroup) =>
            productGroup.products.length > 0 ? (
              <div key={productGroup.name}>
                <Carousel
                  responsive={responsive}
                  swipeable={true}
                  draggable={true}
                  removeArrowOnDeviceType={["tablet", "mobile"]}
                  partialVisible={true}
                >
                  {productGroup.products.map((product) => (
                    <div key={product.id} className="card-container">
                      <CardProduct
                        entrepreneur={product.entrepreneur.name}
                        id={product.entrepreneur.id}
                        image={[
                          product.image1 !== "none"
                            ? baseUrlImgs + product.image1
                            : noImage,
                          product.image2 !== "none"
                            ? baseUrlImgs + product.image2
                            : null,
                          product.image3 !== "none"
                            ? baseUrlImgs + product.image3
                            : null,
                        ].filter(Boolean)}
                        title={product.name}
                        description={product.description}
                        price={Math.floor(product.price)}
                        onProductClick={handleOpenModal}
                      />
                    </div>
                  ))}
                </Carousel>
              </div>
            ) : (
              <div key={productGroup.name} className="text-center mt-3">
                <Typography text="Este emprendedor aún no tiene productos." />
              </div>
            )
          )
        )
      ) : entrepreneurs.length === 0 ? (
        <div className="text-center mt-3">
          <Typography text="No hay emprendedores." />
        </div>
      ) : (
        entrepreneurs.map((entrepreneur) => (
          <div key={entrepreneur.name}>
            <div className="d-flex justify-content-between align-items-center">
              {entrepreneurs.length > 0 && (
                <Link
                  to={`/emprendedor/${entrepreneur.id}`}  // Ruta del perfil del emprendedor
                  style={{ textDecoration: 'none', color: 'inherit' }}
                >
                  <Typography text={entrepreneur.name} as="h2" underlineOnHover={true} />
                </Link>
              )}
            </div>
            <Carousel
              responsive={responsive}
              swipeable={true}
              draggable={true}
              removeArrowOnDeviceType={["tablet", "mobile"]}
              partialVisible={true}
            >
              {entrepreneur.products.length > 0 ? (
                entrepreneur.products.map((product) => (
                  <div key={product.id} className="card-container">
                    <CardProduct
                      entrepreneur={entrepreneur.name}
                      id={entrepreneur.id}
                      image={[
                        product.image1 !== "none"
                          ? baseUrlImgs + product.image1
                          : noImage,
                        product.image2 !== "none"
                          ? baseUrlImgs + product.image2
                          : null,
                        product.image3 !== "none"
                          ? baseUrlImgs + product.image3
                          : null,
                      ].filter(Boolean)}
                      title={product.name}
                      description={product.description}
                      price={Math.floor(product.price)}
                      onProductClick={handleOpenModal}
                    />
                  </div>
                ))
              ) : (
                <div className="text-center mt-3">
                  {searching ? (
                    <Typography text="No hay resultados para la búsqueda." />
                  ) : (
                    <Typography text="Este emprendedor aún no tiene productos." />
                  )}
                </div>
              )}
            </Carousel>
          </div>
        ))
      )}

      {isModalOpen && (
        <ModalProduct
          onClose={handleCloseModal}
          content={modalContent}
          show={isModalOpen}
          showButtonVisitProfile={showButtonVisitProfile}
          visitProfileEntrepreneur={visitProfileEntrepreneur}
        />
      )}
    </Container>
  );
}

export default Products;
