import httpClient from "../lib/httpClient";

export const getEntrepreneurWithProducts = async (idEmprendedor) => {
    try {
      const response = await httpClient.get("/entrepreneur/products/" + idEmprendedor);
      const data = response.data.data;

      const dataArray = [];
      dataArray.push(data);

      if (Array.isArray(dataArray)) {
        return dataArray;
      } else {
        console.error("La respuesta de la API no es un array:", data);
      }

    } catch (error) {
      throw error(error)
    }
  };


  export const getAllEntrepreneursWithProducts = async () => {
    try {
      const response = await httpClient.get("/entrepreneur/products/");
      const data = response.data.data;
  
      if (Array.isArray(data)) {
        return data
      } else {
        console.error("La respuesta de la API no es un array:", data);
      }
    } catch (error) {
      console.error(error);
    }
  };


  export const getCategoryById = async (idCategory) => {
    try {
      const response = await httpClient.get("/category/" + idCategory);
      const data = response.data.data;
  
      if (data) {
        return data
      } else {
        console.error("La respuesta de la API no es un array:", data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  export const getCategories = async () => {
    try {
      const response = await httpClient.get("/category/");
      const data = response.data.data;
  
      if (data) {
        return data
      } else {
        console.error("La respuesta de la API no es un array:", data);
      }
    } catch (error) {
      console.error(error)
      throw error;
    }
  };


  export const getEntrepreneurs = async (idCategory) => {
    try {
      const response = await httpClient.get("/entrepreneur/");
      const data = response.data.data;
      
      if (data) {
        return data
      } else {
        console.error("La respuesta de la API no es un array:", data);
      }
    } catch (error) {
      console.error(error);
      throw error;  
    }
  };

  export const getEntrepreneursProtected = async () => {
    try {
      const options = {}
      options['params'] = {api_token_admins: localStorage.getItem('api_token_admin')}
      const response = await httpClient.get("/admin/entrepreneurs", options);
      const data = response.data.data

      if (data) {
        return(data)
      } else {
        throw new Error("La respuesta de la API no es un array:", data);
      }
    } catch (error) {
      throw error;
    }
  };

  export const postLogin = async (data, options) => {
    try {
      const response = await httpClient.postFormData("/user/login", data);
      const responseData = response.data;

      return responseData

    } catch (error) {
      console.error(error);

      throw error;
    }
  };

    
  export const postLogout = async (data, options) => {
    try {
      const response = await httpClient.postFormData("/user/login", data);
      const responseData = response.data;

      return responseData

    } catch (error) {
      console.error(error);

      throw error;
    }
  };

  export const logoutAdmin = async () => {
    try {
      const options = {}
      options['data'] = {api_token_admins: localStorage.getItem('api_token_admin')}
      const response = await httpClient.post("/admin/logout", options);
      const responseData = response.data;

      return responseData

    } catch (error) {
      console.error(error);
      
      throw error;
    }
  };

  export const getLimitEntrepreneursByCategory = async (id, limit) => {
    try {
      const response = await httpClient.get("entrepreneur/category/"+id+"?limit=" + limit);
      const data = response.data.data

      if (data) {
        return(data)
      } else {
        console.error("La respuesta de la API no es un array:", data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  export const getEntrepreneursByCategory = async (id) => {
    try {
      const response = await httpClient.get("entrepreneur/category/"+id);
      const data = response.data.data

      if (data) {
        return(data)
      } else {
        console.error("La respuesta de la API no es un array:", data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  export const getProfileInformation = async () => {
    try {
      const options = {}
      options['params'] = {api_token: localStorage.getItem('api_token')}
      const response = await httpClient.get("/user/profile", options);
      const data = response.data.data

      if (data) {
        return(data)
      } else {
        console.error("La respuesta de la API no es un array:", data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  export const getCheckApiTokenIsValid = async (token) => {
    try {
      const options = {}
      options['params'] = {[token]: localStorage.getItem('api_token')}
      const response = await httpClient.get("/user/profile", options);
      const data = response.data.data

      if (data) {
        return(data)
      } else {
        throw new Error("La respuesta de la API no es un array:", data);
      }
    } catch (error) {
      throw error;
    }
  };

  export const getCheckApiTokenAdminIsValid = async (token) => {
    try {
      const options = {}
      options['params'] = {api_token_admins: localStorage.getItem('api_token_admin')}
      const response = await httpClient.get("/admin/check-token", options);
      const data = response.data.data
      if (data) {
        return(data)
      } else {
        throw new Error("La respuesta de la API no es un array:", data);
      }
    } catch (error) {
      throw error;
    }
  };


  export const getProductsAdmin = async () => {
    try {
      const options = {}
      options['params'] = {api_token: localStorage.getItem('api_token')}
      const response = await httpClient.get("/user/products", options);
      const data = response.data.data

      if (data) {
        return(data)
      } else {
        console.error("La respuesta de la API no es un array:", data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  export const postRegister = async (data) => {
    try {
      const response = await httpClient.postFormData("/entrepreneur", data);
      return response;     

    } catch (error) {
      throw error;
    }
  };


  export const logout = async () => {
    try {
      const options = {}
      options['params'] = {api_token: localStorage.getItem('api_token')}
      const response = await httpClient.post("/user/logout", options);
      const responseData = response.data;

      return responseData

    } catch (error) {
      console.error(error);
      
      throw error;
    }
  };

  export const postLoginAdmin = async (data, options) => {
    try {
      const response = await httpClient.postFormData("/admin", data);
      const responseData = response.data;

      return responseData

    } catch (error) {
      console.error(error);

      throw error;
    }
  };

  export const patchEditProfile = async (formData) => {
    try {
      // Agregar el token de verificación al formData
      formData.append("api_token", localStorage.getItem('api_token'));
  
      // Construir la URL con el parámetro _method=PATCH
      const url = "/entrepreneur?_method=PATCH";
  
      // Enviar la solicitud POST utilizando el método postFormData y retornar la respuesta
      await httpClient.postFormData(url, formData);

      
  
    } catch (error) {
      throw error;
    }
  };
  
  
  export const patchActivateProduct = async (productId, productActive) => {

    try {
      const options = {
        data: {
          active: productActive,
          api_token: localStorage.getItem('api_token')
        },
        headers: {
          "Content-Type": "application/json",
        }
      };

      const response = await httpClient.patch("/product/active/" + productId, options);

      return response.data
    } catch (error) {
      throw error;
    }
  };

  export const delProduct = async (productId, productActive) => {

    try {
      const options = {
        data: {
          api_token: localStorage.getItem('api_token')
        },
        headers: {
          "Content-Type": "application/json",
        }
      };

      const response = await httpClient.delete("/product/" + productId, options);

      return response.data
    } catch (error) {
      throw error;
    }
  };

  export const postCreateProduct = async (data) => {
    try {
      data.api_token = localStorage.getItem('api_token')
      const response = await httpClient.postFormData("/product", data);
      const responseData = response.data;

      return responseData

    } catch (error) {
      throw error;
    }
  }

  export const patchUpdateProduct = async (data, id) => {
    try {
      data.api_token = localStorage.getItem('api_token')
      const response = await httpClient.postFormData("/product/" + id + "?_method=PATCH", data );
      const responseData = response.data;

      return responseData

    } catch (error) {
      console.error(error);

      throw error;
    }
  }


  export const patchActivateEntrepreneur = async (entrepreneurId, entrepreneurStatus) => {

    try {
      const options = {
        data: {
          active: entrepreneurStatus,
          api_token_admins: localStorage.getItem('api_token_admin')
        },
        headers: {
                  "Content-Type": "application/json",
                }
        };
        
      const response = await httpClient.patch("/admin/entrepreneur/active/" + entrepreneurId, options);
        
      return response.data
      
    } catch (error) {
        throw error;
      }
    };

export const patchChangePass = async (data) => {
    try {
      const options = {
        data: {
          api_token: localStorage.getItem('api_token'),
          actual_pass: data.actual_pass,
          new_pass: data.new_pass,
          confirm_pass: data.confirm_pass
        },
        headers: {
          "Content-Type": "application/json",
        }
      };
      await httpClient.patch("/user/change", options);

    } catch (error) {
      console.error(error);

      throw error;
    }
  }

  export const postSendMessage = async (id, message) => {
    try {
      const response = await httpClient.postFormData("/contact/send-message/" + id, message);
      const responseData = response.data;

      return responseData

    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  export const getSearchProduct = async (term) => {
    try {
      const options = {}
      options['params'] = {'term': term}
      const response = await httpClient.get("/product/search", options);
      return response.data

    } catch (error) {
      if(error.response.status === 302) {
        return error.response.data
      } else
        throw error;
      }
  };

  export const postForgotPassword = async (data) => {
    try {
      const response = await httpClient.post("/user/forgot-password", { data });
      return response.data; // Suponiendo que quieres devolver solo los datos de la respuesta
    } catch (error) {
      console.error("Error in postForgotPassword:", error);
      throw error; // Lanza el error para que se maneje en el componente que llama a esta función
    }
  };

  export const patchResetPassword = async (data) => {
    try {
      const options = {
        data: {
          token: data.api_token,
          email: data.email,
          password: data.password
        },
        headers: {
          "Content-Type": "application/json",
        }
      };
      const response = await httpClient.patch("/user/reset-password", options);
      return response.data; // Devolver solo los datos de la respuesta
    } catch (error) {
      console.error("Error in patchResetPassword:", error);
  
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error("Server responded with:", error.response.data);
        console.error("Status code:", error.response.status);
        // Handle specific error codes (e.g., 422 for validation errors)
        if (error.response.status === 422) {
          // Display validation errors to the user
          alert("Validation errors: " + JSON.stringify(error.response.data)); 
        }
      } else if (error.request) {
        // The request was made but no response was received
        console.error("No response received from server.");
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error("Error setting up request:", error.message);
      }
  
      throw error; // Re-throw for higher-level handling
    }
  };


  
  