  import React from "react";
  import './styles/NavsideAdmin.css'
  import {
    CDBSidebar,
    CDBSidebarContent,
    CDBSidebarFooter,
    CDBSidebarHeader,
    CDBSidebarMenu,
    CDBSidebarMenuItem,
  } from "cdbreact";
  import { baseUrlImgs } from '../../parametria'
  import noImage from "../../assets/no-image.jpeg" 
import Typography from "../Typography";

  function NavsideAdmin(props) {
    const { handleOptionClick, user, error } = props;


    return (
      <div className="sidebar-admin">
      {error ? (
      <></>
      ) : (
        <CDBSidebar textColor="#fff" backgroundColor="#333">
          <CDBSidebarHeader  prefix={<i className="fa fa-bars fa-large"></i>}>
            <img
              src={ user.logo !== "none" ? baseUrlImgs + user.logo : noImage}
              alt="Profile"
              className="img-fluid rounded-circle"
            />
              <div className="user-name ml-5 fs-4 mt-3">{user.name}</div>
          </CDBSidebarHeader>

          <CDBSidebarContent className="sidebar-content">
            <CDBSidebarMenu>
              <div onClick={() => handleOptionClick("EditarPerfil")} className="cursor-pointer">
                <CDBSidebarMenuItem icon="user">Editar perfil</CDBSidebarMenuItem>
              </div>
              <div onClick={() => handleOptionClick("Productos")} className="cursor-pointer">
                <CDBSidebarMenuItem icon="shopping-cart">Productos</CDBSidebarMenuItem>
              </div>
              <div onClick={() => handleOptionClick("CambiarContrasena")} className="cursor-pointer">
                <CDBSidebarMenuItem icon="lock">Cambiar contraseña</CDBSidebarMenuItem>
              </div>
            </CDBSidebarMenu>
          </CDBSidebarContent>

          <CDBSidebarFooter>
          <div onClick={() => handleOptionClick("Salir")} className="cursor-pointer">
            <CDBSidebarMenuItem icon="sign-out-alt">Salir</CDBSidebarMenuItem>
          </div>
        </CDBSidebarFooter>
        </CDBSidebar>
      )}
      </div>
    );
  }

  export default NavsideAdmin;
