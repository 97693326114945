import App from './App';
import { createRoot } from 'react-dom/client';
// Importing the Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';
import TagManager from 'react-gtm-module'
 
const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTMID
}

TagManager.initialize(tagManagerArgs)

const root = createRoot(document.getElementById('root'));

root.render(<App />);
