import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Typography from "../Typography";
import { useNavigate } from "react-router-dom";
//Comentario para ver si identifica cambio mayuscula
function Search() {
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  const handleSearchClick = () => {
    setSearchOpen(!searchOpen);

    navigate(`/busqueda?term=${searchTerm}`);
  };

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      // Si la tecla presionada es "Enter", activa la búsqueda
      handleSearchClick();
    }
  };

  return (
    <FloatingLabel
      controlId="floatingInputGrid"
      label={<Typography text={"Buscar productos"} style={{ fontSize: "11px", fontWeight: "normal", color: "silver" }}></Typography>}
      className="d-flex align-items-center"
      style={{ width: "80%" }}
    >
      <Form.Control
        type="email"
        className="search-input"
        placeholder=""
        style={{
          border: "none",
          width: "100%",
        }}
        value={searchTerm}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
      />
      <InputGroup.Text
        style={{
          border: "none",
          background: "none",
          cursor: "pointer",
        }}
        onClick={handleSearchClick}
      >
        <FontAwesomeIcon
          icon={faSearch}
          className={`search-icon ${searchOpen ? 'active' : ''}`}
        />
      </InputGroup.Text>
    </FloatingLabel>
  );
}

export default Search;
