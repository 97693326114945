import { Modal, Button, Form, Container, Spinner } from "react-bootstrap";
import { useState } from "react";
import { postCreateProduct } from "../../clients/phpApp";
import ModalEditImage from "../../components/Modal/ModalEditImage";
import './styles/ModalAddProduct.css';

const ModalAddProduct = (props) => {
  const { showAddModal, setDisableMessage, setShowAddModal, setProducts } = props;
  const [showModalModalImage, setShowModalImage] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [imageField, setImageField] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [newProduct, setNewProduct] = useState({
    name: "",
    description: "",
    price: "",
    active: "1",
    image1: null,
    image2: null,
    image3: null,
  });
  const [validated, setValidated] = useState(false);

  const handleSaveNewProduct = async (event) => {
    try {
      event.preventDefault();
      event.stopPropagation();

      const form = event.currentTarget;
      if (form.checkValidity() === false) {
        setValidated(true);
        return;
      }

      setIsLoading(true);

      const addedProduct = await postCreateProduct(newProduct);
      if (addedProduct.data.active === "1") {
        addedProduct.data.active = 1;
      } else {
        addedProduct.data.active = 0;
      }

      setProducts((prevProducts) => [...prevProducts, addedProduct.data]);
      setDisableMessage(null);
      handleCloseAddModal();
    } catch (error) {
      setDisableMessage("No se pudo agregar el producto. Inténtalo de nuevo.");
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleImageSelection = (event, imgField) => {
    try {
      const file = event.target.files[0];
      const imageUrl = URL.createObjectURL(file);
      setSelectedFile(imageUrl);
      setImageField(imgField);
      setShowModalImage(true);
    } catch (error) {
      return;
    }
  };

  function closedModalImage() {
    setShowModalImage(false);
  }

  function handleImage(newImage) {
    setSelectedFile(newImage);
    setNewProduct((prevProduct) => ({
      ...prevProduct,
      [imageField]: newImage,
    }));
  }

  const handleCloseAddModal = () => {
    setShowAddModal(false);
    setNewProduct({
      name: "",
      description: "",
      price: "",
      active: "1",
      image1: null,
      image2: null,
      image3: null,
    });
    setValidated(false);
  };

  return (
    <Container className="modal-add-product">
      <Modal show={showAddModal} onHide={handleCloseAddModal}>
        <Modal.Header closeButton>
          <Modal.Title>Agregar Nuevo Producto</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            noValidate
            validated={validated}
            onSubmit={handleSaveNewProduct}
            id="addProduct"
          >
            <Form.Group>
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                type="text"
                placeholder="Nombre del Producto"
                onChange={(e) =>
                  setNewProduct({ ...newProduct, name: e.target.value })
                }
                required
                disabled={isLoading}
              />
              <Form.Control.Feedback type="invalid">
                Este campo es obligatorio.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>Descripción</Form.Label>
              <Form.Control
                type="text"
                placeholder="Descripción del Producto"
                value={newProduct.description || ""}
                onChange={(e) =>
                  setNewProduct({ ...newProduct, description: e.target.value })
                }
                required
                disabled={isLoading}
              />
              <Form.Control.Feedback type="invalid">
                Este campo es obligatorio.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>Precio</Form.Label>
              <Form.Control
                type="number"
                placeholder="Precio del Producto"
                value={newProduct.price || ""}
                onChange={(e) =>
                  setNewProduct({ ...newProduct, price: e.target.value })
                }
                required
                disabled={isLoading}
              />
              <Form.Control.Feedback type="invalid">
                Este campo es obligatorio.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>Estado</Form.Label>
              <Form.Control
                as="select"
                value={newProduct.active || ""}
                onChange={(e) =>
                  setNewProduct({ ...newProduct, active: e.target.value })
                }
                required
                disabled={isLoading}
              >
                <option value="1">Activo</option>
                <option value="0">Inactivo</option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Este campo es obligatorio.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>Imagen 1</Form.Label>
              <Form.Control
                id="image1"
                label="Cargar Imagen1"
                onChange={(event) => handleImageSelection(event, "image1")}
                accept="image/*"
                type="file"
                required
                disabled={isLoading}
              />
              <Form.Control.Feedback type="invalid">
                Este campo es obligatorio.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>Imagen 2</Form.Label>
              <Form.Control
                id="image2"
                label="Cargar Imagen2"
                onChange={(event) => handleImageSelection(event, "image2")}
                accept="image/*"
                type="file"
                disabled={isLoading}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Imagen 3</Form.Label>
              <Form.Control
                id="image3"
                label="Cargar Imagen3"
                onChange={(event) => handleImageSelection(event, "image3")}
                accept="image/*"
                type="file"
                disabled={isLoading}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseAddModal} disabled={isLoading}>
            Cancelar
          </Button>
          <Button variant="primary" type="submit" form="addProduct" disabled={isLoading}>
            {isLoading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : 'Guardar Producto'}
          </Button>
        </Modal.Footer>
      </Modal>
      {showModalModalImage && (
        <ModalEditImage
          imageUrl={selectedFile}
          showModalImage={showModalModalImage}
          closedModalImage={closedModalImage}
          handleImage={handleImage}
        />
      )}
    </Container>
  );
};

export default ModalAddProduct;
