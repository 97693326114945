import axios from "axios";
import { fileSystemUrl } from "./fileSystemInf";

const fetch = async (url, options = {}) => {
  try {
    const instance = axios.create({
      baseURL: fileSystemUrl,
    });
    // Interceptor REQUEST
    instance.interceptors.request.use(
      (conf) => {
        //console.log('1 Interceptor request conf: ', conf);
        return conf;
      },
      (error) => {
          //console.log('1 Interceptor request error: ', error);
        return Promise.reject(error);
      }
    );

    // Interceptor RESPONSE
    instance.interceptors.response.use(
      (response) => {
        //console.log('Interceptor response: ', response);
        return response;
      },
      (error) => {
        //console.log('Interceptor response error: ', error);
        return Promise.reject(error);
      }
    );

    const response = await instance.request({
      url,
      data: options["data"],
      params: options["params"],
      method: options["method"],
      headers: options["headers"],
      cancelToken: options["cancelFn"]
        ? new axios.CancelToken(options["cancelFn"])
        : null,
    });

    return response;
  } catch (err) {
    if (axios.isCancel(err)) {
      throw new Error("request-cancelled");
    } else {
      throw err;
    }
  }
};

const get = async (url, options = {}) => {
  return await fetch(url, {
    method: "GET",
    ...options,
  });
};

const post = async (url, options = {}) => {
  return await fetch(url, {
    method: "POST",
    ...options,
  });
};

const postFormData = async (url, formData, options = {}) => {
  const headers = {
    "Content-Type": "multipart/form-data",
    ...options.headers,
  };
    return await post(url, {
      ...options,
      headers,
      data: formData,
    });
};

const put = async (url, options = {}) => {
  return await fetch(url, {
    method: "PUT",
    ...options,
  });
};

const patch = async (url, options = {}) => {
  return await fetch(url, {
    method: "PATCH",
    ...options,
  });
};

const del = async (url, options = {}) => {
  return await fetch(url, {
    method: "DELETE",
    ...options,
  });
};

export default {
  get,
  post,
  postFormData,
  put,
  patch,
  delete: del,
};
