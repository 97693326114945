import React from "react";
import { useNavigate } from 'react-router-dom';
import CardEntrepreneurs from "../../components/Cards/cardEntrepreneurs";
import { Container } from "react-bootstrap";
import { getEntrepreneursByCategory } from "../../clients/phpApp";
import { useState, useEffect } from "react";
import BannerSection from "../../components/Banner/Banner";
import ServiceUnavailable from "../../components/Exceptions/ServiceUnavailable";
import shuffle from 'lodash/shuffle';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import "./styles/Home.css";
import ArticleSection from "../../components/Articles/Articles";

function Home(props) {
  const [entrepreneurs, setEntrepreneurs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const { updateIdEmprendedor, updateIdCategory } = props;
  const navigate = useNavigate();

  useEffect(() => {
    const entrepreneursCategory = async () => {
      setIsLoading(true);
      try {
        const entrepreneurWithCategory1 = await getEntrepreneursByCategory(1);
        const entrepreneurWithCategory2 = await getEntrepreneursByCategory(2);
        const entrepreneurWithCategory3 = await getEntrepreneursByCategory(3);

        if (!entrepreneurWithCategory1 || !entrepreneurWithCategory2 || !entrepreneurWithCategory3) {
          throw new Error("Error fetching entrepreneurs. Response not successful.");
        }

        setEntrepreneurs([
          { category: 1, categoryName: 'Arte', entrepreneurs: entrepreneurWithCategory1 },
          { category: 2, categoryName: 'Home & Deco', entrepreneurs: entrepreneurWithCategory2 },
          { category: 3, categoryName: 'Moda', entrepreneurs: entrepreneurWithCategory3 }
        ]);
      } catch (error) {
        console.error("Error al solicitar categorías y clientes", error);
        setIsLoading(false);
        setHasError(true);
      } finally {
        setIsLoading(false);
      }
    };

    entrepreneursCategory();
  }, []);

  function showEntrepreneurById({ id }) {
    navigate(`/emprendedor/${id}`);
  }

  function showEntrepreneurByCategory(idCategory) {
    if (entrepreneurs.length > 0) {
      updateIdCategory(idCategory);
      navigate('/categorias');
    } else {
      console.error("No hay datos de categoría disponibles para mostrar emprendedores.");
    }
  }

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
      partialVisibilityGutter: 40,
      arrows: true
    },
    desktop: {
      breakpoint: { max: 3000, min: 1200 },
      items: 4,
      partialVisibilityGutter: 0,
      arrows: true
    },
    tablet: {
      breakpoint: { max: 1200, min: 530 },
      items: 2,
      partialVisibilityGutter: 80,
      arrows: true
    },
    mobile: {
      breakpoint: { max: 530, min: 0 },
      items: 2,
      partialVisibilityGutter: 7,
    }
  };

  return (
    <>
      <BannerSection />
      <ArticleSection />
      <Container>
        {isLoading && (<p>Cargando...</p>)}
        {hasError && <ServiceUnavailable />}
        {!isLoading && !hasError && entrepreneurs.map((category, idx) => {
          const shuffledEntrepreneurs = shuffle(category.entrepreneurs);
          return (
            <div key={idx}>
              <p className="category-title">{category.categoryName}</p>
              <Carousel
                responsive={responsive}
                swipeable={true}
                draggable={true}
                removeArrowOnDeviceType={["tablet", "mobile"]}
                partialVisible={true}
              >
                {shuffledEntrepreneurs.map((entrepreneur) => (
                  <CardEntrepreneurs
                    key={entrepreneur.id}
                    entrepreneur={entrepreneur.name}
                    logo={entrepreneur.logo}
                    categoria={entrepreneur.category.name}
                    id={entrepreneur.id}
                    onLogoClick={showEntrepreneurById}
                    showFooter={false}
                  />
                ))}
              </Carousel>
            </div>
          );
        })}
      </Container>
    </>
  );
}

export default Home;
