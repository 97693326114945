import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import NavsideAdmin from "../../components/NavSide/NavsideAdmin";
import ChangePassword from "../../components/Administration/ChangePassword";
import DataTableProducts from "../../components/Administration/DataTableProducts"
import EditInformation from "../../components/Administration/EditInformation";
import { getProfileInformation } from "../../clients/phpApp";
import { useNavigate } from "react-router-dom";
import { logout } from "../../clients/phpApp";
import './styles/PanelAdministration.css'

function Salir() {
  return <h2>Salir</h2>;
}

function PanelAdministracion(props) {
  const { toggleAdminPanel, setIsLoggedIn } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState({});
  const [error, setError] = useState(false);
  const [selectedOption, setSelectedOption] = useState("EditarPerfil");
  const navigate = useNavigate();

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    if (option === "Salir") {
      handleLogout();
    }
    else {
      if (window.innerWidth < 768) {
        window.scrollTo(0, document.body.scrollHeight);
      }
    }
  };

  useEffect(() => {
    toggleAdminPanel(true);

    return () => {
      toggleAdminPanel(false);
    };
  }, []);


  useEffect(() => {
    setIsLoading(true);

    // Llamar a la función y manejar la promesa
    getProfileInformation()
      .then((data) => {
        console.log(data)
        if (data === undefined) {
          setError(true);
          localStorage.removeItem("api_token");
        } else {
          setUser(data);
        }
      })
      .catch((error) => {
        console.error("Error al obtener los datos del emprendedor:", error);
        setError(true);
        localStorage.removeItem("api_token");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const handleLogout = () => {
    logout()
      .then(response => {
        if (response.data === 'Sesion cerrada') {
          localStorage.removeItem("api_token");
          setIsLoggedIn(false);
          navigate('/')
        }
        else {
          localStorage.removeItem("api_token");
          throw new Error("Error: Sesión no cerrada");
        }
      })
      .catch(error => {
        localStorage.removeItem("api_token");
        throw new Error("Error: Sesión no cerrada");
      });
  };

  return (
    <Container fluid className="ps-0" style={{ background: error || isLoading ? "" : "white" }}>
      {isLoading ? (<></>) : (
        <Row>
          <Col className="columns-admin" d={0} md={4} lg={3} xl={2} style={{ boxShadow: "4px 0px 6px rgba(0, 0, 0, 0.2)" }}>
            {error ? (<></>) : (<NavsideAdmin toggleAdminPanel={toggleAdminPanel} handleOptionClick={handleOptionClick} user={user} error={error} />)}
          </Col>
          <Col
            className={`${selectedOption === "Productos"
              ? "data-table-products"
              : "generic-admin-style"
              }`}
          >
            {isLoading ? (<p>Cargando...</p>) :
              <main style={{ overflowX: "auto" }}>
                {selectedOption === "EditarPerfil" && <EditInformation user={user} setUser={setUser} error={error} isLoading={isLoading} />}
                {selectedOption === "Productos" && <DataTableProducts />}
                {selectedOption === "CambiarContrasena" && <ChangePassword />}
                {selectedOption === "Salir" && <Salir />}
              </main>
            }
          </Col>
        </Row>)}
    </Container>
  );
}

export default PanelAdministracion;
