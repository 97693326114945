import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CardEntrepreneurs from "../../components/Cards/cardEntrepreneurs";
import { Container } from "react-bootstrap";
import { getEntrepreneurs } from "../../clients/phpApp";
import ServiceUnavailable from "../../components/Exceptions/ServiceUnavailable";
import "./styles/EntrepreneursList.css";

function EntrepreneursList(props) {
  const [entrepreneurs, setEntrepreneurs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);

    getEntrepreneurs()
      .then((entrepreneurs) => {
        setEntrepreneurs(entrepreneurs);
      })
      .catch((error) => {
        setError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  function showEntrepreneurById({ id }) {
    navigate(`/emprendedor/${id}`);
  }



  return (
    <Container className="entreprenuers-container">
      {!isLoading && !error && (
        entrepreneurs.map((entrepreneur) => (
          <CardEntrepreneurs
            entrepreneur={entrepreneur.name}
            logo={entrepreneur.logo}
            categoria={entrepreneur.category.name}
            id={entrepreneur.id}
            onLogoClick={showEntrepreneurById}
            showFooter={true}
            key={entrepreneur.id} // Don't forget to add a unique key for each mapped element
          />
        ))
      )}
    </Container>
  );
}

export default EntrepreneursList;
