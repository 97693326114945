import React from "react";
import Card from 'react-bootstrap/Card';
import "./styles/CardProduct.css"
import Typography from "../Typography";


function CardProduct({ image, title, description, price, entrepreneur, id, onProductClick }) {

  function handleClick() {
    onProductClick({ image, title, description, price, entrepreneur, id, onProductClick })
  }

  return (
    <Card className="hand-cursor border-0 obscure cardProduct" style={{ marginBottom: "16px" }} onClick={handleClick}>
      <Card.Img variant="top" src={image[0]} className="image-style" />
      <Card.Body>
        <div className="contentp">
          <Card.Text className="card-price"><Typography text={"$ " + Math.floor(price)}></Typography></Card.Text>
          <Card.Title className="card-title"><Typography text={title} ></Typography></Card.Title>
        </div>
      </Card.Body>
    </Card>
  );
}

export default CardProduct;
