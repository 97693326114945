import { Modal, Button } from "react-bootstrap";

const ModalConfirmDelete = (props) => {
  const { showDeleteModal, handleCloseDeleteModal, handleConfirmDelete } = props;
  return (
    <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
      <Modal.Header closeButton>
        <Modal.Title>Confirmar Eliminación</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        ¿Estás seguro de que deseas eliminar este producto?
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseDeleteModal}>
          Cancelar
        </Button>
        <Button variant="danger" onClick={handleConfirmDelete}>
          Eliminar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalConfirmDelete;
